import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import FHIR from 'fhirclient';
import { useSearchParams } from 'react-router-dom';

// SMART on FHIR launch endpoint (Epic EHR Launch SSO)
export const SmartLaunch = () => {
    const [searchParams] = useSearchParams();
    const iss = searchParams.get('iss')?.toLowerCase() ?? '';

    // TODO: Retrieve this mapping from database/AWS instead
    let orgIssMap: Map<string, string> = new Map([
        ['', 'epic-sandbox'],
        ['https://fhir.epic.com/interconnect-fhir-oauth/api/fhir/r4', 'epic-sandbox'],
    ]);
    const orgRedirectPath = orgIssMap.get(iss) ?? 'unrecognized';

    // TODO: Handle case when orgId is 'unrecognized', indicating an unfamiliar ISS value

    FHIR.oauth2.authorize({
        // TODO: Retrieve the client id as an environment variable since the production client id will be different
        clientId: '9c08f612-9c32-44de-a915-73b98c74968c',
        // Note: ISS value is provided when supporting the Standalone Launch flow
        //iss: 'https://fhir.epic.com/interconnect-fhir-oauth/api/fhir/r4',
        scope: 'openid fhirUser launch launch/encounter launch/patient email',
        redirectUri: `smart-launch-handler/${orgRedirectPath}`,
    });

    return <ProcessingOverlay enabled caption="Launching..." />;
};
