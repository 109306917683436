import { CircularProgress, Typography, makeStyles } from '@material-ui/core';
import theme from 'App/theme';
import { ProcedureList } from 'client/components/ProcedureList';
import { useListProcedures } from 'client/hooks/procedure/useListProcedures';
import Panel from 'common/components/UI/Panel';
import { useLocation, useParams } from 'react-router-dom';
import VideoExport from '../VideoExport/VideoExportByProcedureList';

const useStyles = makeStyles({
    section: {
        margin: theme.spacing(0, 3.5, 3),
        textAlign: 'left',
    },
    sectionHeader: {
        margin: theme.spacing(0, 3.5, 2),
        textAlign: 'left',
    },
    sectionContent: {
        textAlign: 'center',
        margin: theme.spacing(0, 2, 3),
        height: '100%',
    },
    sectionContentEmbedded: {
        textAlign: 'center',
        margin: theme.spacing(0, 5.5, 3),
        height: '100%',
    },
    text1: {
        fontSize: '1.2rem',
        paddingLeft: '10px',
        textAlign: 'left',
        fontWeight: 400,
    },
    text2: {
        fontSize: '1rem',
        padding: '30px 20px',
        textAlign: 'center',
        fontWeight: 400,
    },
    headerText: {
        color: 'white',
    },
});

export const PatientProcedures = () => {
    const { id: patientId } = useParams<{ id: string }>();
    const location = useLocation();
    const patientInitials = location.state?.patientInitials;
    const { procedures: liveProcedures, isLoading } = useListProcedures(patientId);

    const classes = useStyles();

    return (
        <>
            <div className={classes.section}>
                <Typography variant="h5" className={classes.headerText}>
                    Patient
                </Typography>
                <Panel style={{ height: 'auto', width: '30%' }}>
                    <Typography className={classes.text1}>Initials: {patientInitials}</Typography>
                    <Typography className={classes.text1}>Epic Id: {patientId}</Typography>
                </Panel>
            </div>
            <div className={classes.sectionHeader}>
                <Typography variant="h5" className={classes.headerText}>
                    Live Procedures
                </Typography>
            </div>
            {isLoading ? (
                <div className={classes.sectionContent}>
                    <CircularProgress size={50} />
                </div>
            ) : liveProcedures.length === 0 ? (
                <div className={classes.sectionContent}>
                    <Typography className={`${classes.text2}`}>There are no live procedures to display.</Typography>
                </div>
            ) : (
                <div className={classes.sectionContent} style={{ marginBottom: '-4px' }}>
                    <ProcedureList procedures={liveProcedures}></ProcedureList>
                </div>
            )}
            <div className={classes.sectionHeader}>
                <Typography variant="h5" className={classes.headerText}>
                    Archived Procedures
                </Typography>
            </div>
            <div className={classes.sectionContentEmbedded}>
                <VideoExport patientId={patientId} />
            </div>
        </>
    );
};
