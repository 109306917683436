export function captureStreamImage(videoElementId: string = 'local-video-output') {
    const video = document.getElementById(videoElementId) as HTMLVideoElement | null;

    if (video === null) {
        console.error('captureStreamImage video is null');
        return Promise.reject(new Error('captureStreamImage video is null'));
    }

    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const ctx = canvas.getContext('2d');

    if (ctx === null) {
        console.error('captureStreamImage ctx is null');
        return Promise.reject(new Error('captureStreamImage ctx is null'));
    }

    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    return new Promise<Blob>(function (resolve, reject) {
        canvas.toBlob(function (blob) {
            if (blob) {
                resolve(blob);
            } else {
                console.error('captureStreamImage failed to create Blob');
                reject('captureStreamImage failed to create Blob');
            }
        });
    });
}
