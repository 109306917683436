import type * as api from 'common/types/api';

export const config = {
    acceptance: {
        all: () => ['acceptance'],
        me: () => ({
            key: [...config.acceptance.all(), 'me'],
            path: `/acceptances/me`,
        }),
    },
    agreement: {
        all: () => ['agreement'],
        sign: () => ({
            path: `/agreements/sign`,
        }),
        signPDF: () => ({
            path: `/agreements/signpdf`,
        }),
    },
    attendee: {
        all: () => ['attendee'],
        details: () => [...config.attendee.all(), 'detail'],
        detail: (procedureId: number) => ({
            key: [...config.attendee.details(), procedureId],
            path: `/procedures/${procedureId}/attendees/me`,
        }),
        primaries: () => [...config.attendee.all(), 'primary'],
        primary: (procedureId: number) => ({
            key: [...config.attendee.primaries(), procedureId],
            path: `/procedures/${procedureId}/attendees/primary`,
        }),
        upsert: () => ({
            path: (procedureId: number) => `/procedures/${procedureId}/attendees/me`,
        }),
    },
    procedure: {
        all: () => ['procedure'],
        list: (patientId?: string) => ({
            key: [...config.procedure.all(), 'list', patientId],
            path: `/procedures/live/${patientId}`,
        }),
        searches: () => [...config.procedure.all(), 'search'],
        search: (request: api.SearchProceduresRequest) => ({
            key: [...config.procedure.searches(), request],
            path: '/procedures/search',
        }),
        details: () => [...config.procedure.all(), 'detail'],
        detail: (procedureId: number) => ({
            key: [...config.procedure.details(), procedureId],
            path: `/procedures/${procedureId}`,
        }),
        vods: (procedureId?: number) => ({
            key: [...config.procedure.searches(), procedureId, 'vod'],
            path: `/procedures/${procedureId}/vod`,
        }),
        update: () => ({
            key: ['procedures', 'update'],
            path: `/procedures/update`,
        }),
        deleteProcedure: () => ({
            key: [...config.procedure.searches(), 'delete'],
            path: (procedureId: number) => `/procedures/delete/${procedureId}`,
        }),
    },
    doctors: {
        all: () => ['doctors'],
        list: () => ({
            key: [...config.doctors.all(), 'list'],
            path: '/doctors',
        }),
        search: () => ({
            key: [...config.doctors.all(), 'search'],
            path: '/doctors/search',
        }),
        summary: () => ({
            key: ['doctors', 'summary'],
            path: '/doctors/summary',
        }),
        get: () => ({
            key: [...config.doctors.all(), 'get'],
            path: '/doctors/get',
        }),
        assignAssistant: () => ({
            key: [...config.doctors.all(), 'assistant', 'assign'],
            path: '/doctors/assistant/assign',
        }),
        listAssistant: (doctorId: number) => ({
            key: [...config.doctors.all(), 'assistant', doctorId],
            path: `/doctors/assistant/${doctorId}`,
        }),
        listDoctorsUserAssists: () => ({
            key: [...config.doctors.all(), 'assistant', 'list-doctors-user-assists'],
            path: `/doctors/assistant/list-doctors-user-assists`,
        }),
        removeAssistant: () => ({
            key: [...config.doctors.all(), 'assistant', 'remove'],
            path: `/doctors/assistant/remove`,
        }),
        listClips: (doctorId: number) => ({
            key: ['doctor', 'clips', doctorId],
            path: `/doctor/clips/${doctorId}`,
        }),
    },
    facilities: {
        all: () => ['facilities'],
        list: () => ({
            key: [...config.facilities.all(), 'list'],
            path: '/facilities',
        }),
        details: () => [...config.facilities.all(), 'detail'],
        detail: (facilityId: number) => ({
            key: [...config.facilities.details(), facilityId],
            path: `facilities/${facilityId}`,
        }),
        listAll: () => ({
            key: ['facilities', 'all'],
            path: '/facilities/all',
        }),
    },
    orders: {
        all: () => ['orders'],
        create: () => ({
            path: `/orders`,
        }),
        activate: () => ({
            path: (orderId: number) => `/orders/${orderId}/activate`,
        }),
        listMine: () => ({
            key: [...config.orders.all(), 'me', 'list'],
            path: `/orders/me`,
        }),
        list: (searchTerm?: string) => ({
            key: [...config.orders.all(), 'list'],
            path: `/orders${searchTerm ? `?searchTerm=${searchTerm}` : ''}`,
        }),
        listFromPaymentId: (paymentId?: number) => ({
            key: [...config.orders.all(), 'list'],
            path: `/orders/payment/${paymentId}`,
        }),
        details: () => [...config.facilities.all(), 'detail'],
        detail: (orderId: number) => ({
            key: [...config.orders.all(), orderId],
            path: `/orders/${orderId}`,
        }),
        listWithLicenseOfProcedure: (procedureId: number) => ({
            key: [...config.orders.all(), procedureId, 'license'],
            path: `/orders/me/procedure/${procedureId}`,
        }),
    },
    payments: {
        all: () => ['payments'],
        detail: (paymentId: number | undefined) => ({
            key: [...config.payments.all(), paymentId],
            path: `/payments/${paymentId}`,
        }),
        detailsFromIntent: (paymentIntent: string | null) => ({
            key: [...config.payments.all(), paymentIntent],
            path: `/payments/intent/${paymentIntent}`,
        }),
    },
    camera: {
        all: () => ['camera'],
        changePosition: (streamId: number | undefined) => ({
            key: [...config.camera.all(), 'position'],
            path: `streaming/${streamId}/ptz`,
        }),
        gotoHomePosition: (streamId: number | undefined) => ({
            key: [...config.camera.all(), 'goto-home'],
            path: `streaming/${streamId}/goto-home`,
        }),
    },
    survey: {
        all: () => ['surveyQuestions'],
        list: (procedureId: number | undefined) => ({
            key: [...config.survey.all(), 'procedure', procedureId],
            path: `/questions/procedure/${procedureId}`,
        }),
        saveAnswer: () => ({
            path: `/questions/`,
        }),
    },
    videoexport: {
        all: () => ['video-export-procedures'],
        canView: () => ({
            key: [...config.videoexport.all(), 'can-view-export'],
            path: '/video-export/can-view',
        }),
        canDelete: (jobId: string) => ({
            key: [...config.videoexport.all(), jobId, 'can-delete-export'],
            path: `/video-export/${jobId}/can-delete`,
        }),
        deleteExport: () => ({
            key: [...config.videoexport.all(), 'delete-export'],
            path: (jobId: string) => `/video-export/${jobId}/delete`,
        }),
        list: (filter?: string, dateStart?: Date, dateEnd?: Date, facilityId?: number, patientId?: string) => {
            const listParameters = [
                filter && `filter=${filter ?? ''}`, //parameter is required, but can be empty
                dateStart && `dateStart=${dateStart?.toISOString() ?? ''}`,
                dateEnd && `dateEnd=${dateEnd?.toISOString() ?? ''}`,
                facilityId && `facilityId=${facilityId}`,
                patientId && `patientId=${patientId}`,
            ]
                .filter(k => k)
                .join('&');
            return {
                key: [...config.videoexport.all(), filter, dateStart, dateEnd, facilityId, patientId, 'list'],
                path: `/video-export/list${listParameters ? `?${listParameters}` : ''}`,
            };
        },
        createOverlay: () => ({
            key: [...config.videoexport.all(), 'video-export-create'],
            path: '/video-export/create',
        }),
        jobList: () => ({
            key: [...config.videoexport.all(), 'video-export-job-list'],
            path: `/video-export/jobs`,
        }),
        listFavorites: () => ({
            key: [...config.videoexport.all(), 'video-export-favorites'],
            path: '/video-export/favorites',
        }),
        addFavorite: () => ({
            key: [...config.videoexport.all(), 'add-favorite'],
            path: `/video-export/favorites`,
        }),
        deleteFavorite: () => ({
            key: [...config.videoexport.all(), 'delete-favorite'],
            path: (favoriteId: number) => `/video-export/favorites/${favoriteId}`,
        }),
        updateTitle: () => ({
            key: [...config.videoexport.all(), 'update-title'],
            path: `/video-export/title`,
        }),
        getFavoriteInfo: (procedureId: number) => ({
            key: [...config.videoexport.all(), `favorite-info-${procedureId}`],
            path: `/video-export/favorites/${procedureId}`,
        }),
        vodsForExport: (procedureId?: number) => ({
            key: [...config.procedure.searches(), procedureId, 'vod'],
            path: `/procedures/${procedureId}/vodForExport`,
        }),
    },
    sharing: {
        all: () => ['sharing'],
        share: () => ({
            key: [...config.sharing.all(), 'share'],
            path: `/video-export/share`,
        }),
        listOfAvailableToShare: (procedureId: number) => ({
            key: [...config.sharing.all(), procedureId, 'list-of-available-to-share'],
            path: `/video-export/share/${procedureId}`,
        }),
        listUsersSharedWith: (procedureId: number) => ({
            key: [...config.sharing.all(), procedureId, 'list-users-shared-with'],
            path: `/video-export/share/to/${procedureId}`,
        }),
        removeShare: (procedureId: number) => ({
            key: [...config.sharing.all(), procedureId, 'delete-share'],
            path: (shareId: number) => `/video-export/share/${procedureId}/${shareId}`,
        }),
    },
    hubspot: {
        registerContact: () => ({
            path: `/hubspot/contact/register`,
        }),
    },
    user: {
        all: () => ['user'],
        list: () => ({
            key: ['users'],
            path: `/users`,
        }),
        updateRoles: () => ({
            key: ['users', 'roles', 'update'],
            path: `/users/roles/update`,
        }),
        updateGroups: () => ({
            key: ['users', 'groups', 'update'],
            path: `/users/groups/update`,
        }),
        updateCuts: () => ({
            key: ['users', 'cuts', 'update'],
            path: `/users/cuts/update`,
        }),
        roles: () => ({
            key: ['roles'],
            path: `/roles`,
        }),
        updateFacilities: () => ({
            key: ['users', 'facilities', 'update'],
            path: `/users/facilities/update`,
        }),
    },
    templates: {
        list: () => ({
            key: ['templates'],
            path: `/templates`,
        }),
        update: () => ({
            key: ['templates', 'update'],
            path: `/templates/update`,
        }),
        send: () => ({
            key: ['templates', 'send'],
            path: `/templates/send`,
        }),
    },
    chats: {
        list: (procedureId: number) => ({
            key: ['chats'],
            path: `/chats/${procedureId}`,
        }),
    },
    mobile: {
        canUseCuts: () => ({
            key: ['mobile', 'can-use-cuts'],
            path: `/mobile/can-use-cuts`,
        }),
    },
    streams: {
        deleteStream: () => ({
            key: ['streams', 'delete-stream'],
            path: `/procedures/delete-stream`,
        }),
    },
    clips: {
        list: () => ({
            key: ['clips'],
            path: `/clips`,
        }),
        info: (jobId: string) => ({
            key: ['clips', jobId],
            path: `/clips/${jobId}`,
        }),
        update: () => ({
            key: ['clips', 'update'],
            path: `/clips/update`,
        }),
        comments: (jobId: string) => ({
            key: ['clips', 'comments', jobId],
            path: `/clips/comments/${jobId}`,
        }),
        commentCreate: () => ({
            key: ['clips', 'comments', 'create'],
            path: `/clips/comments/create`,
        }),
    },
    photos: {
        list: (procedureId: number) => ({
            key: [...config.procedure.searches(), procedureId, 'photos'],
            path: `/procedures/${procedureId}/photos`,
        }),
    },
};
