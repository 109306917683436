import { FC } from 'react';
import { Box, ButtonProps, IconButton, makeStyles } from '@material-ui/core';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

interface CapturePhotoButtonProps extends ButtonProps {
    onCapture: () => void;
}

const useStyles = makeStyles({
    container: {
        bottom: 0,
        right: 0,
        position: 'absolute',
        zIndex: 50,
    },
    button: {
        fontSize: '2rem !important',
        width: '65px !important',
        height: '65px !important',
        color: 'white !important',
    },
});

export const CapturePhotoButton: FC<CapturePhotoButtonProps> = ({ onCapture }) => {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.container} onClick={onCapture}>
                <Box>
                    <IconButton color="primary">
                        <AddAPhotoIcon className={`${classes.button}`} fontSize="large" />
                    </IconButton>
                </Box>
            </Box>
        </>
    );
};
