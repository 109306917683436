import { Api } from 'common/utils/api';
import { config } from 'mobile/hooks/config';
import { useMutation } from 'react-query';

interface Props {
    procedureId: number;
}

interface ReturnType {
    uploadUrl: string;
}

export const usePhotoUploadStart = (procedureId: number) => {
    const { path } = config.photo.uploadStart(procedureId);

    const mutation = useMutation((body: Props) => Api.post<ReturnType>({ path, body }));

    return {
        usePhotoUploadStart: mutation.mutateAsync,
    };
};
