import React, { ChangeEvent, useState } from 'react';
import { Box, CircularProgress, Grid, Typography, makeStyles, Button, TextField, Select, MenuItem } from '@material-ui/core';
import { Cancel, Save } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import theme from 'App/theme';
import ExportProcedureStreamsPanel from 'client/components/VideoExport/ExportProcedureStreamsPanel';
import { PhotosPanel } from 'client/components/VideoExport/PhotosPanel';
import { useUpdateProcedure } from 'client/hooks/procedure/useUpdateProcedure';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { IdName, MUIProcedureDataResponse } from 'common/types/api';
import CommonUtils from 'common/utils/CommonUtils';
import { GetTimeFromDateString, GetTimeSpent, ToDateShort } from 'common/utils/DateUtil';
import { EditIcon } from 'common/utils/Icons';

const formatMilliseconds = CommonUtils.formatMilliseconds;
const styles = {
    box: {
        border: '1px',
    },
    boxHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    boxHeaderText: {
        color: theme.palette.primary.contrastText,
    },
    w100: {
        width: '100%',
        display: 'block',
    },
    editorMargin: {
        margin: '10px',
    },
};

export interface VideoExportExpandedPanelProps {
    data: MUIProcedureDataResponse;
    devices: IdName[];
    doctors: IdName[];
    companies: string[];
    brands: string[];
}

export const VideoExportExpandedPanel = (props: VideoExportExpandedPanelProps) => {
    const classes = makeStyles(styles)();
    const { data, doctors, companies, brands } = props;
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const { notify } = useNotifier();
    const { updateProcedure } = useUpdateProcedure();
    const [procedureInfo, setProcedureInfo] = useState({
        title: data.title,
        patientInitials: data.patientInitials ?? '',
        deviceId: data.deviceId?.toString() ?? '',
        doctorId: data.doctorId?.toString() ?? '0',
        brandName: data.brandName ?? 'N/A',
        notes: data.notes ?? '',
        companyName: data.companyName ?? 'N/A',
        surgeryStart: data?.surgeryStart ? new Date(data?.surgeryStart).toLocaleString('en-US', { timeZone: data?.timeZone || 'UTC' }) : null,
        surgeryEnd: data?.surgeryEnd ? new Date(data?.surgeryEnd).toLocaleString('en-US', { timeZone: data?.timeZone || 'UTC' }) : null,
        surgerySpent: GetTimeSpent(data.surgeryStart, data.surgeryEnd),
        timeZone: data?.timeZone ?? 'America/New_York',
        roomName: data?.device?.roomName,
    });
    const passOnInfo = {
        doctorName: data?.doctor?.name,
        companyName: data?.companyName,
        brandName: data?.brandName,
        procedureTitle: data?.title,
        deviceName: data?.deviceName,
        patientInitials: data?.patientInitials,
        timeZone: data?.timeZone,
        doctorId: data?.doctorId,
        deviceId: data?.deviceId,
        surgeryStart: data.surgeryStart,
        surgeryEnd: data.surgeryEnd,
        surgerySpent: GetTimeSpent(data.surgeryStart, data.surgeryEnd),
    };

    const onHandleDoctorChange = async (
        event: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        const id = Number(event.target.value);
        setProcedureInfo(prev => ({
            ...prev,
            doctorId: id.toString(),
        }));
    };

    const onHandleDeviceChange = async (
        event: ChangeEvent<{
            name?: string | undefined;
            value: string;
        }>
    ) => {
        const newRoomName = event.target.value;
        setProcedureInfo(prev => ({
            ...prev,
            roomName: newRoomName.toString(),
        }));
        props.data.device.roomName = newRoomName.toString();
    };

    const onHandleCompanyChange = async (
        event: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        const strValue = event.target.value as string;
        setProcedureInfo(prev => ({
            ...prev,
            companyName: strValue,
        }));
    };

    const onHandleBrandChange = async (
        event: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        const strValue = event.target.value as string;
        setProcedureInfo(prev => ({
            ...prev,
            brandName: strValue,
        }));
    };

    const onTextTitleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setProcedureInfo(prev => ({
            ...prev,
            title: e.target.value,
        }));
    };

    const onTextInitialChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setProcedureInfo(prev => ({
            ...prev,
            patientInitials: e.target.value,
        }));
    };

    const onSurgeryStartChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const dN = ToDateShort(new Date());
        const stamp = new Date(`${dN} ${e.target.value}.000`);
        setProcedureInfo(prev => ({
            ...prev,
            surgeryStart: stamp.toLocaleString(),
            surgerySpent: GetTimeSpent(stamp.toLocaleString(), prev.surgeryEnd),
        }));
    };

    const onSurgeryEndChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const dN = ToDateShort(new Date());
        const stamp = new Date(`${dN} ${e.target.value}.000`);
        setProcedureInfo(prev => ({
            ...prev,
            surgeryEnd: stamp.toLocaleString(),
            surgerySpent: GetTimeSpent(prev.surgeryStart, stamp.toLocaleString()),
        }));
    };

    const onSaveProcedure = async () => {
        if (procedureInfo.title.Length <= 0) {
            notify(new Message({ title: 'Procedure title is required', type: 'error' }));
            return;
        }

        if (!procedureInfo.deviceId) {
            notify(new Message({ title: 'Room name is required', type: 'error' }));
            return;
        }

        if (procedureInfo.surgeryStart && procedureInfo.surgeryEnd) {
            const valid = procedureInfo.surgeryEnd > procedureInfo.surgeryStart;

            if (!valid) {
                notify(new Message({ title: 'Invalid surgery time range', type: 'error' }));
                return;
            }
        }

        setIsSaving(true);

        try {
            await updateProcedure({
                id: data?.id,
                title: procedureInfo.title,
                patientInitials: procedureInfo.patientInitials ?? '',
                deviceId: Number(procedureInfo.deviceId) ?? 0,
                doctorId: Number(procedureInfo.doctorId) === 0 ? null : Number(procedureInfo.doctorId),
                brandName: procedureInfo.brandName ?? 'N/A',
                notes: procedureInfo.notes ?? '',
                companyName: procedureInfo.companyName ?? 'N/A',
                surgeryStart: procedureInfo.surgeryStart,
                surgeryEnd: procedureInfo.surgeryEnd,
                roomName: procedureInfo.roomName,
            });
            notify(new Message({ title: 'Edit procedure is successfully saved', type: 'success' }));
            setIsEditing(false);
        } catch {
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className={classes.editorMargin}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box style={{ display: 'flex', justifyContent: 'end', gap: 7 }}>
                        {isEditing ? (
                            <>
                                <Button
                                    variant="outlined"
                                    startIcon={<Cancel />}
                                    style={{ borderRadius: 0 }}
                                    onClick={() => setIsEditing(false)}
                                    disabled={isSaving}
                                >
                                    Cancel{' '}
                                    {isSaving && (
                                        <>
                                            &nbsp;&nbsp;
                                            <CircularProgress size={15} thickness={8} color="primary" />
                                        </>
                                    )}
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<Save />}
                                    style={{ borderRadius: 0 }}
                                    onClick={onSaveProcedure}
                                    disabled={isSaving}
                                    color="primary"
                                >
                                    Save{' '}
                                    {isSaving && (
                                        <>
                                            &nbsp;&nbsp;
                                            <CircularProgress size={15} thickness={8} color="primary" />
                                        </>
                                    )}
                                </Button>
                            </>
                        ) : (
                            <Button variant="outlined" startIcon={<EditIcon />} style={{ borderRadius: 0 }} onClick={() => setIsEditing(true)}>
                                Edit
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Procedure ID
                            </Typography>
                        </Box>
                        <Typography align="center" variant="body1" noWrap>
                            {data?.id}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Title
                            </Typography>
                        </Box>
                        {isEditing ? (
                            <Box style={{ display: 'flex' }}>
                                <FormControl sx={{ m: 1, flex: 'auto' }} variant="filled" size="small">
                                    <TextField defaultValue={data?.title} variant="standard" onChange={onTextTitleChange} />
                                </FormControl>
                            </Box>
                        ) : (
                            <Typography align="center" variant="body1" noWrap>
                                {data?.title}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Doctor Name
                            </Typography>
                        </Box>

                        {isEditing ? (
                            <Box style={{ display: 'flex' }}>
                                <FormControl sx={{ m: 1, flex: 'auto' }} variant="filled" size="small">
                                    <Select id="doctor-select" value={procedureInfo.doctorId} onChange={onHandleDoctorChange}>
                                        {doctors.map((d: IdName) => (
                                            <MenuItem key={d.id} value={d.id.toString()}>
                                                {d.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        ) : (
                            <Typography align="center" variant="body1" noWrap>
                                {data?.doctor?.name}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Start Time
                            </Typography>
                        </Box>
                        <Typography align="center" variant="body1" noWrap>
                            {data?.startTime ? new Date(data?.startTime).toLocaleString('en-US', { timeZone: data?.timeZone || 'UTC' }) : ''}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Length
                            </Typography>
                        </Box>
                        <Typography align="center" variant="body1" noWrap>
                            {data?.timeSpent ? formatMilliseconds(data?.timeSpent) : 'N/A'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Patient Initials
                            </Typography>
                        </Box>
                        {isEditing ? (
                            <Box style={{ display: 'flex' }}>
                                <FormControl sx={{ m: 1, flex: 'auto' }} variant="filled" size="small">
                                    <TextField defaultValue={data?.patientInitials} variant="standard" onChange={onTextInitialChange} />
                                </FormControl>
                            </Box>
                        ) : (
                            <Typography align="center" variant="body1" noWrap>
                                {data?.patientInitials || 'No Initials'}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Brand Name
                            </Typography>
                        </Box>
                        {isEditing ? (
                            <Box style={{ display: 'flex' }}>
                                <FormControl sx={{ m: 1, flex: 'auto' }} variant="filled" size="small">
                                    <Select id="brand-select" value={procedureInfo.brandName} onChange={onHandleBrandChange}>
                                        {brands.map((d: string) => (
                                            <MenuItem key={d} value={d}>
                                                {d}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        ) : (
                            <Typography align="center" variant="body1" noWrap>
                                {procedureInfo?.brandName || 'No Brand Provided'}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Company Name
                            </Typography>
                        </Box>
                        {isEditing ? (
                            <Box style={{ display: 'flex' }}>
                                <FormControl sx={{ m: 1, flex: 'auto' }} variant="filled" size="small">
                                    <Select id="company-select" value={procedureInfo.companyName} onChange={onHandleCompanyChange}>
                                        {companies.map((d: string) => (
                                            <MenuItem key={d} value={d}>
                                                {d}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        ) : (
                            <Typography align="center" variant="body1" noWrap>
                                {procedureInfo?.companyName || 'N/A'}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Room Name
                            </Typography>
                        </Box>
                        {isEditing ? (
                            <Box style={{ display: 'flex' }}>
                                <FormControl sx={{ m: 1, flex: 'auto' }} variant="filled" size="small">
                                    <TextField defaultValue={data?.device?.roomName} variant="standard" onChange={onHandleDeviceChange} />
                                </FormControl>
                            </Box>
                        ) : (
                            <Typography align="center" variant="body1" noWrap>
                                {data?.device?.roomName || 'N/A'}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Surgery Start
                            </Typography>
                        </Box>
                        {isEditing ? (
                            <Box style={{ display: 'flex' }}>
                                <FormControl sx={{ m: 1, flex: 'auto' }} variant="filled" size="small">
                                    <TextField
                                        type="time"
                                        defaultValue={GetTimeFromDateString(procedureInfo?.surgeryStart)}
                                        variant="standard"
                                        onChange={onSurgeryStartChange}
                                    />
                                </FormControl>
                            </Box>
                        ) : (
                            <Typography align="center" variant="body1" noWrap>
                                {procedureInfo?.surgeryStart
                                    ? new Date(procedureInfo?.surgeryStart).toLocaleString('en-US', { timeZone: procedureInfo?.timeZone || 'UTC' })
                                    : 'N/A'}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Surgery End
                            </Typography>
                        </Box>
                        {isEditing ? (
                            <Box style={{ display: 'flex' }}>
                                <FormControl sx={{ m: 1, flex: 'auto' }} variant="filled" size="small">
                                    <TextField
                                        type="time"
                                        defaultValue={GetTimeFromDateString(procedureInfo?.surgeryEnd)}
                                        variant="standard"
                                        onChange={onSurgeryEndChange}
                                    />
                                </FormControl>
                            </Box>
                        ) : (
                            <Typography align="center" variant="body1" noWrap>
                                {procedureInfo?.surgeryEnd
                                    ? new Date(procedureInfo?.surgeryEnd).toLocaleString('en-US', { timeZone: procedureInfo?.timeZone || 'UTC' })
                                    : 'N/A'}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Surgery Length
                            </Typography>
                        </Box>
                        <Typography align="center" variant="body1" noWrap>
                            {procedureInfo?.surgerySpent ? formatMilliseconds(procedureInfo?.surgerySpent) : 'N/A'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <ExportProcedureStreamsPanel procedureId={data?.id} enabledAPI={true} download={false} passOnInfo={passOnInfo} />
                </Grid>
                <PhotosPanel procedureId={data?.id} />
            </Grid>
        </div>
    );
};
