import { useCallback, useState } from 'react';
import { CircularProgress, Dialog, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, makeStyles, Typography } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/Download';
import { useListPhotos } from 'client/hooks/procedure/photos/useListPhotos';

interface Props {
    procedureId: number;
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        maxWidth: '95vw',
    },
    image: {
        height: '100%',
        maxHeight: '150px',
        maxWidth: '400px',
    },
    imageList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        gap: '5px',
        maxWidth: '95vw',
        cursor: 'pointer',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background: 'none',
    },
    buttonDownload: {
        color: '#6ACFE6',
        filter: 'drop-shadow(0px 0px 8px black)',
    },
    buttonDelete: {
        color: '#E82020',
        filter: 'drop-shadow(0px 0px 8px black)',
    },
}));

export const PhotosPanel: React.FC<Props> = ({ procedureId }) => {
    const photos = useListPhotos(procedureId);
    const classes = useStyles();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [dialogUrl, setDialogUrl] = useState('');

    const handleView = useCallback((url: string) => {
        setDialogUrl(url);
        setDialogOpen(true);
    }, []);

    const handleDownload = useCallback((url: string) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, []);

    if (photos.isLoading) {
        return (
            <Grid item xs={12} className={classes.root}>
                <Typography variant="h6">
                    Photos <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />
                </Typography>
            </Grid>
        );
    }

    const hasPhotos = photos.photos?.length ? photos.photos?.length > 0 : false;

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6">Photos</Typography>
            </Grid>
            <Grid item xs={12} className={classes.root}>
                {!hasPhotos && <Typography variant="subtitle1">No photos captured for this procedure.</Typography>}
                {hasPhotos && (
                    <ImageList className={classes.imageList} cols={2.5}>
                        {photos.photos?.map(x => (
                            <>
                                <ImageListItem key={x.key}>
                                    <img
                                        src={x.getUrl}
                                        className={classes.image}
                                        alt="procedure stream capture"
                                        onClick={() => handleView(x.getUrl)}
                                    />
                                    <ImageListItemBar
                                        classes={{
                                            root: classes.titleBar,
                                            title: classes.title,
                                        }}
                                        actionIcon={
                                            <>
                                                <IconButton onClick={() => handleDownload(x.getUrl)}>
                                                    <DownloadIcon className={classes.buttonDownload} />
                                                </IconButton>
                                                {/* <IconButton>
                                                    <DeleteIcon className={classes.buttonDelete} />
                                                </IconButton> */}
                                            </>
                                        }
                                    ></ImageListItemBar>
                                </ImageListItem>
                            </>
                        ))}
                    </ImageList>
                )}
            </Grid>
            <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)} maxWidth="lg" fullWidth>
                <img src={dialogUrl} alt="procedure stream capture" />
            </Dialog>
        </>
    );
};
