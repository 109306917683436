import { config } from 'circulator/hooks/config';
import { LastCopy } from 'common/types/circulator';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export const useGetLastCopy = () => {
    const { key, path } = config.procedure.lastCopy();

    const result = useQuery(key, () => Api.get<LastCopy>({ path }));

    return {
        lastCopy: result.data,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
        isError: result.isError,
    };
};
