import { ForwardedRef, forwardRef, useCallback, useState } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import { ContentCut, Videocam } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import PhotoIcon from '@mui/icons-material/Photo';
import Spinner from '@mui/material/CircularProgress';
import VideoPreview from 'client/components/VideoExport/VideoPreview';
import { useListPhotos } from 'client/hooks/procedure/photos/useListPhotos';
import { useGetProcedureStreamsForExport } from 'client/hooks/procedure/useGetProcedureStreamsForExport';
import { useGetExportList } from 'client/hooks/videoExport/useGetExportList';
import { StreamDetails } from 'common/types/procedure';
import { fileSizeToString, formatMilliseconds } from 'mobile/format-utilities';
import type { ExportStreams, Procedure } from '../../../../../layers/database/nodejs/database';
import { styles } from './ArchivesStyles';

interface ArchivesDetailPanelProps {
    procedure: Procedure;
    state: 'closed' | 'open' | 'opening' | 'closing';
}

const useGetProcedureStreams = (procedure: Procedure, enabled = true) => {
    const returnValue = useGetProcedureStreamsForExport({ procedureId: procedure.id, enabled });
    return { ...returnValue, data: returnValue.streams ?? ([] as StreamDetails[]) };
};

const useGetProcedureClips = (procedure: Procedure, enabled = true): { data: any[] | undefined; isLoading: boolean } => {
    const exportList = useGetExportList();
    const data = exportList.data?.filter(item => item.procedureId === procedure.id) ?? ([] as ExportStreams[]);
    return {
        ...exportList,
        data,
    };
};

const ArchiveClipRow = (props: { clip: ExportStreams; procedure: Procedure }) => (
    <>
        <div>
            <ContentCut />
        </div>
        <div style={{ gridColumn: 'span 3', justifyContent: 'center', display: 'flex' }}>
            <VideoPreview
                procedureId={props.procedure.id}
                doctorName={props.procedure.doctorName ?? undefined}
                deviceName={props.clip.title ?? undefined}
                procedureTitle={props.procedure.title ?? undefined}
                brandName={props.procedure.brandName ?? undefined}
                companyName={props.procedure.companyName ?? undefined}
                patientInitials={props.procedure.patientInitials ?? undefined}
                s3Filename={props.clip.streamLocation as string}
                s3Key={'clips/' + (props.clip.streamLocation ?? '').split('/').pop()}
                exporting={false}
                isMobile={true}
            ></VideoPreview>
        </div>
        <div>{formatMilliseconds(props.clip.videoLength ?? undefined)}</div>
        <div>{props.procedure.patientInitials}</div>
    </>
);

const ArchiveStreamRow = ({ stream, procedure }: { stream: StreamDetails; procedure: Procedure }) => (
    <>
        <div style={{ backgroundColor: '#87A5B1' }}>
            <Videocam />
        </div>
        <div style={{ gridColumn: 'span 3', backgroundColor: '#87A5B1', justifyContent: 'center', display: 'flex' }}>
            <VideoPreview
                procedureId={procedure.id}
                doctorName={procedure.doctorName ?? undefined}
                deviceName={stream.streamName ?? undefined}
                procedureTitle={procedure.title ?? undefined}
                brandName={procedure.brandName ?? undefined}
                companyName={procedure.companyName ?? undefined}
                patientInitials={procedure.patientInitials ?? undefined}
                s3Filename={stream.s3FileName}
                s3Key={stream.s3Key}
                exporting={true}
                isMobile={true}
            ></VideoPreview>
        </div>
        <div style={{ backgroundColor: '#87A5B1' }}>{fileSizeToString(stream.s3Size, 0)}</div>
        <div style={{ backgroundColor: '#87A5B1' }}>{procedure.patientInitials}</div>
    </>
);

const ArchivePhotoRow = (props: { url: string }) => {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const handleView = useCallback((url: string) => {
        setDialogOpen(true);
    }, []);
    const handleDownload = useCallback((url: string) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, []);

    return (
        <>
            <div>
                <PhotoIcon />
            </div>
            <div style={{ gridColumn: 'span 4', justifyContent: 'center', display: 'flex' }}>
                <img
                    src={props.url}
                    onClick={() => handleView(props.url)}
                    alt="procedure stream capture"
                    style={{ maxHeight: '50px', maxWidth: '200px' }}
                ></img>
            </div>
            <div>
                <IconButton onClick={() => handleDownload(props.url)}>
                    <DownloadIcon style={{ color: 'white' }} />
                </IconButton>
            </div>
            <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)} maxWidth="lg" fullWidth>
                <img src={props.url} alt="procedure stream capture" />
            </Dialog>
        </>
    );
};

export const ArchivesDetailPanel = forwardRef((props: ArchivesDetailPanelProps, ref: ForwardedRef<HTMLDivElement>) => {
    const originalStreams = useGetProcedureStreams(props.procedure, props.state !== 'closed');
    const clips: { isLoading: boolean; data: ExportStreams[] } = useGetProcedureClips(props.procedure, props.state !== 'closed') as {
        isLoading: boolean;
        data: ExportStreams[];
    };
    const photos = useListPhotos(props.procedure.id);

    return (
        <div className={`${styles.detail} detail-${props.state}`} ref={ref}>
            <div className={styles.detailContent}>
                <div className={styles.data}>
                    {originalStreams.isLoading ? (
                        <div className={styles.fullWidthDataItem}>
                            <Spinner />
                            Loading...
                        </div>
                    ) : (originalStreams.data?.length ?? 0) === 0 ? (
                        <div className={styles.fullWidthDataItem}>No streams found</div>
                    ) : (
                        originalStreams.data?.map(stream => <ArchiveStreamRow stream={stream} procedure={props.procedure} key={stream.s3Key} />)
                    )}

                    {clips.isLoading ? (
                        <div className={styles.fullWidthDataItem}>
                            <Spinner />
                            Loading...
                        </div>
                    ) : (clips.data?.length ?? 0) === 0 ? (
                        <div className={styles.fullWidthDataItem}>No clips found</div>
                    ) : (
                        clips.data?.map(clip => (
                            <ArchiveClipRow clip={clip} procedure={props.procedure} key={`${clip.jobId}_${clip.streamLocation}`} />
                        ))
                    )}

                    {photos.isLoading ? (
                        <div className={styles.fullWidthDataItem}>
                            <Spinner />
                            Loading...
                        </div>
                    ) : (photos.photos?.length ?? 0) === 0 ? (
                        <div className={styles.fullWidthDataItem}>No photos found</div>
                    ) : (
                        photos.photos?.map(photo => <ArchivePhotoRow url={photo.getUrl} key={photo.key} />)
                    )}
                </div>
            </div>
        </div>
    );
});
