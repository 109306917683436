import { Api } from 'common/utils/api';
import { useMutation } from 'react-query';

interface Props {
    uploadUrl: string;
    blob: Blob;
    filename: string;
}

export const usePhotoUpload = () => {
    const mutation = useMutation((data: Props) => {
        const file = new File([data.blob], data.filename);

        return Api.put({
            path: data.uploadUrl,
            body: file,
            config: {
                headers: {
                    // we are using S3 presigned auth, so don't attached the default auth
                    Authorization: undefined,
                    'Content-Disposition': `attachment; filename=${data.filename}`,
                    'Content-Type': 'image/png',
                },
            },
        });
    });

    return mutation;
};
