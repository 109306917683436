/* eslint-disable no-console */
import axios from 'axios';
import { generateHeader } from 'common/utils/APIUtils';

export interface OidcUserAttributes {
    oidcId: string;
    oidcGroupName: string;
    organization: string;
    givenName: string;
    familyName: string;
    email: string;
}

export const provisionOidcUser = async (userInfo: OidcUserAttributes) => {
    const headers = generateHeader();
    const body = {
        oidcId: userInfo.oidcId,
        oidcGroupName: userInfo.oidcGroupName,
        organization: userInfo.organization,
        givenName: userInfo.givenName,
        familyName: userInfo.familyName,
        email: userInfo.email,
    };
    return axios.post('/users/oidc/provision', body, { headers });
};
