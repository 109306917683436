import { useState, useEffect } from 'react';
import { useMutateUser } from 'client/hooks/user/useMutateUser';
import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import { OidcUserAttributes } from 'common/services/oidcServices';
import FHIR from 'fhirclient';
import { useNavigate, useParams } from 'react-router-dom';

const SmartLaunchHandler = () => {
    const { orgId } = useParams<{ orgId: string }>();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { provisionOidcUser } = useMutateUser();

    useEffect(() => {
        FHIR.oauth2
            .ready()
            .then(client => {
                setLoading(true);
                return Promise.all([client.request(`Patient/${client.patient.id}`), client.request(`${client.user.fhirUser}`)]);
            })
            .then(
                async ([patientResult, fhirUserResult]) => {
                    console.log(patientResult);
                    console.log(fhirUserResult);

                    const patientId = patientResult.id ?? '';
                    const patientInitials = `${patientResult.name[0].given.toString()[0]}${patientResult.name[0].family[0]}`;

                    // Create Cognito user if needed and authenticate
                    const username = fhirUserResult.id;
                    const org = orgId ?? '';
                    const givenName = fhirUserResult.name[0].given.toString();
                    const familyName = fhirUserResult.name[0].family;
                    const userEmail =
                        fhirUserResult.telecom !== undefined
                            ? `${fhirUserResult.telecom.find((element: { system: string }) => element.system === 'email')}`.toLowerCase()
                            : `${fhirUserResult.id}@vsurgic.com`.toLowerCase();

                    const oidcUser: OidcUserAttributes = {
                        oidcId: username,
                        oidcGroupName: 'EpicOidc',
                        organization: org,
                        givenName: givenName,
                        familyName: familyName,
                        email: userEmail,
                    };

                    await provisionOidcUser.mutateAsync(oidcUser);

                    setLoading(false);

                    // Redirect to Patient Procedures page
                    navigate(`/patient-procedures/${patientId}`, { state: { patientInitials: patientInitials } });
                },
                error => {
                    console.error(error);
                    return (
                        <>
                            <br />
                            <pre>{error.stack}</pre>
                        </>
                    );
                }
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) return <ProcessingOverlay enabled caption="Authenticating..." />;

    return <ProcessingOverlay enabled caption="Redirecting..." />;
};

export default SmartLaunchHandler;
