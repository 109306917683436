import { config } from 'client/hooks/config';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export function useGetProcedureListForExport(filter: string, dateStart?: Date, dateEnd?: Date, facilityId?: number, patientId?: string) {
    const { key, path } = config.videoexport.list(filter, dateStart, dateEnd, facilityId, patientId);
    const results = useQuery(key, async () => await Api.get({ path }), {});
    return {
        data: results?.data || [],
        isLoading: results.isLoading,
        isFetching: results.isFetching,
    };
}
