import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export function useListPhotos(procedureId: number) {
    const { key, path } = config.photos.list(procedureId);

    const result = useQuery(key, () => Api.get<api.ListPhotos>({ path }), {});

    return {
        photos: result.data,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
