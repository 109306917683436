import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    makeStyles,
    SwipeableDrawer,
    Typography,
} from '@material-ui/core';
import { List, ListItem } from '@mui/material';
import { useListAttendees } from 'circulator/hooks/attendee/useListAttendees';
import { useGetActiveProcedure } from 'circulator/hooks/procedure/useGetActiveProcedure';
import { useMutateProcedure } from 'circulator/hooks/procedure/useMutateProcedure';
import usePreauthorizedUsersStore from 'circulator/hooks/usePreauthorizedUsersStore';
import { Player } from 'common/components/Player';
import { useListAllUserRoles } from 'common/hooks/role/useListUserRoles';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { AttendeeStatus } from 'common/types';
import { Stream } from 'common/types';
import { captureStreamImage } from 'common/utils/streamCapture';
import { attendeeJoinAnimation } from 'mobile/components/AttendeeToggleButton';
import { AutoJoin } from 'mobile/components/AutoJoin';
import { CapturePhotoButton } from 'mobile/components/CapturePhotoButton';
import { VideoPlayer } from 'mobile/components/procedure/VideoPlayer';
import { Background } from 'mobile/components/UI/Background';
import { Header } from 'mobile/components/UI/Header';
import { usePhotoUpload } from 'mobile/hooks/procedure/usePhotoUpload';
import { usePhotoUploadStart } from 'mobile/hooks/procedure/usePhotoUploadStart';
import useMobileLayouts from 'mobile/hooks/useMobileLayouts';
import { useScreenInformation } from 'mobile/hooks/useScreenInformation';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import CutsLogo from '../../img/cuts-logo.png';
import { AttendeeDetail } from '../components/AttendeeDetail';
import { TimeElapsed } from '../components/TimeElapsed';
import { useResetAnimation } from '../hooks/useResetAnimation';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    timeElapsedLabel: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '1rem',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: '1rem',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        maxWidth: '100vw',
        color: '#ff8f00',
        background: 'white',
        width: '100%',
    },
    videoPlayerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100vw',
        flex: 1,
        flexDirection: 'column',
    },
    horizontalTimeElapsed: {
        margin: '0px',
        padding: '10px',
        height: '100%',
        flex: '1 0 auto',
        '& p': {
            '&:first-child': {
                flex: '1 1 auto',
            },
            flex: '1 0 auto',
        },
    },
    marginTop: {
        marginTop: '50px',
    },
    marginTopSpacing: {
        margnTop: '10px',
    },
    alignProgress: {
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            margin: '0 5px',
        },
    },
    feedHidden: {
        maxHeight: '40vh',
        maxWidth: '100%',
        display: 'none',
    },
    hidden: {
        display: 'none',
    },
    attendeeRow: {
        display: 'flex !important',
        flex: '1 !important',
        justifyContent: 'space-between !important',
    },
}));

export const ShotPage = () => {
    const classes = useStyles();
    const { procedure, isLoading: isActiveProcedureLoading, isError: isActiveProcedureError } = useGetActiveProcedure();
    const [isStopping, setStopping] = useState(false);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const procedureMutation = useMutateProcedure();
    const navigate = useNavigate();
    const resetAnimations = useResetAnimation(attendeeJoinAnimation.name);

    // join auto-approve
    const { userRoles, isLoading: isUserRolesLoading, isError: isUserRolesError } = useListAllUserRoles();

    const { addAcceptedRole } = usePreauthorizedUsersStore(
        state => ({
            addAcceptedRole: state.addAcceptedRole,
        }),
        shallow
    );

    useEffect(() => {
        for (const role of userRoles) {
            addAcceptedRole(role);
        }
    }, [addAcceptedRole, userRoles]);

    const { attendees, isLoading: isAttendeesLoading, isError: isAttendeesError } = useListAttendees(procedure?.id);
    const requested = useMemo(() => attendees.filter(attendee => attendee.status === AttendeeStatus.REQUESTED), [attendees]);
    // end join auto-approve
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const oldAttendees = useRef<number | null>(null);
    const currentActuallyJoinedAttendeesCount = attendees.filter(k => k.joinTimes.length > k.leaveTimes.length).length;
    //This bit of math is to check if they are still in the session; this will ping each time they rejoin, which is probably what the user wants.
    const lastJoinOrLeave = Math.max(
        ...attendees
            .flatMap(k => k.leaveTimes)
            .concat(attendees.flatMap(k => k.joinTimes))
            .map(k => k.valueOf())
    );
    useEffect(() => {
        if (oldAttendees.current !== null && oldAttendees.current < currentActuallyJoinedAttendeesCount) {
            resetAnimations();
        }
        oldAttendees.current = currentActuallyJoinedAttendeesCount;
    }, [currentActuallyJoinedAttendeesCount, lastJoinOrLeave, resetAnimations]);

    const isLoading = isActiveProcedureLoading || isAttendeesLoading || isUserRolesLoading;
    const isError = isActiveProcedureError || isAttendeesError || isUserRolesError;

    const { streams = [] } = procedure?.device ?? {};

    const audioStream = useMemo(
        () =>
            streams.filter((stream: Stream) => {
                return stream.isFeatured && stream.type.includes('AUDIO') && !stream.name.includes('Mobile');
            })[0],
        [streams]
    );

    // if no procedure, redirect to create page
    useEffect(() => {
        if (!procedure && !isActiveProcedureLoading) {
            navigate('/mobile');
        }
    }, [isActiveProcedureLoading, navigate, procedure]);
    // end no procedure

    const handleModalCancel = () => {
        setStopping(false);
        setDialogOpen(false);
    };

    const handleModalOpen = () => {
        setDialogOpen(true);
    };

    const handleModalConfirm = async () => {
        setStopping(true);
        try {
            await procedureMutation.stop.mutateAsync(procedure?.id);
            setDialogOpen(false);
            navigate('/mobile/start-shot');
        } catch (error) {
            console.error(error);
        } finally {
            setStopping(false);
        }
    };

    const usePhotoUploadStartMutate = usePhotoUploadStart(procedure?.id);
    const photoUpload = usePhotoUpload();
    const [photoNumber, setPhotoNumber] = useState(1);
    const { notify } = useNotifier();

    const handleCapturePhoto = async () => {
        try {
            const blob = await captureStreamImage();

            const result = await usePhotoUploadStartMutate.usePhotoUploadStart({
                procedureId: procedure?.id,
            });

            await photoUpload.mutateAsync({
                uploadUrl: result.uploadUrl,
                blob: blob,
                filename: `procedure-${procedure?.id}-${photoNumber}.png`,
            });

            setPhotoNumber(x => x + 1);

            notify(new Message({ title: 'Photo captured.', type: 'success' }));

            // const newImg = document.createElement('img');
            // const url = URL.createObjectURL(blob);
            // newImg.src = url;
            // document.body.appendChild(newImg);
        } catch (err) {
            console.error('There was a problem capturing and uploading a procedure photo.', err);
            notify(new Message({ title: 'Unable to capture photo.', type: 'error' }));
        }
    };

    const toggleDrawer = (open?: boolean) => {
        if (open === undefined) setDrawerOpen(!isDrawerOpen);
        else setDrawerOpen(open);
    };

    const screenInformation = useScreenInformation();
    const layouts = useMobileLayouts();

    return (
        <Background>
            <>
                <Header backButtonVisible={true} backButtonOnclick={handleModalOpen} headerImage={CutsLogo} headerImageWidth={300}>
                    {!isError && !isLoading && screenInformation.isLandscape ? (
                        <Grid container item className={`${classes.timeElapsedLabel} ${classes.horizontalTimeElapsed}`}>
                            <Typography>Time Elapsed:</Typography>
                            <TimeElapsed startTime={procedure.startTime}></TimeElapsed>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Header>
                {isError && (
                    <>
                        <Typography variant="h6" component="h6" display="block">
                            Something went wrong. Please try again later.
                        </Typography>
                        <div className="spacing"> </div>
                    </>
                )}
                {!isError && isLoading && (
                    <Typography component="div" variant="body1" className={classes.alignProgress}>
                        <CircularProgress size={20} />
                    </Typography>
                )}
                {!isError && !isLoading && procedure && (
                    <Grid container className={layouts.verticalContainer}>
                        {screenInformation.isPortrait ? (
                            <Grid item className={classes.timeElapsedLabel}>
                                <Typography>Time Elapsed:</Typography>
                                <TimeElapsed startTime={procedure.startTime}></TimeElapsed>
                            </Grid>
                        ) : (
                            <></>
                        )}
                        <Grid item className={layouts.verticalContainer}>
                            <VideoPlayer procedure={procedure} toggleDrawer={toggleDrawer} hasAttendees={currentActuallyJoinedAttendeesCount > 0} />
                            {audioStream && (
                                <div key={audioStream.id} className={classes.hidden}>
                                    <Player
                                        isActive={true}
                                        width={0}
                                        height={0}
                                        src={audioStream.src}
                                        iceServers={JSON.stringify(procedure?.device.iceServers)}
                                        isMainPlayer={false}
                                        muted={false}
                                    />
                                </div>
                            )}
                        </Grid>
                        {requested.map(attendee => (
                            <AutoJoin key={attendee.id} procedureId={procedure.id} attendee={attendee} />
                        ))}
                        <CapturePhotoButton onCapture={handleCapturePhoto} />
                        <SwipeableDrawer anchor={'bottom'} open={isDrawerOpen} onClose={() => toggleDrawer(false)} onOpen={() => toggleDrawer(true)}>
                            <List>
                                {(attendees || []).map(attendee => (
                                    <ListItem key={attendee.userId} className={classes.attendeeRow}>
                                        <Suspense fallback={<CircularProgress size={20} />}>
                                            <AttendeeDetail userId={attendee.userId} attendee={attendee} procedureId={procedure.id} />
                                        </Suspense>
                                    </ListItem>
                                ))}
                            </List>
                        </SwipeableDrawer>
                    </Grid>
                )}
                <Dialog open={isDialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle>
                        <Typography variant="h6" component="h6" display="block" gutterBottom>
                            End Procedure
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText color="primary">
                            <Typography variant="caption" display="block" gutterBottom>
                                Are you sure you want to end this procedure?
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalCancel} variant="outlined" size="small" disabled={isStopping} className="modal-cancel-button">
                            Cancel
                        </Button>
                        <Button onClick={handleModalConfirm} autoFocus variant="contained" size="small" disabled={isStopping}>
                            Confirm
                            {isStopping && (
                                <>
                                    &nbsp;&nbsp;
                                    <CircularProgress size={15} thickness={8} color="primary" />
                                </>
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </Background>
    );
};
