import { Suspense, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { StarRate, StarHalf, FavoriteSharp, FavoriteBorder } from '@mui/icons-material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ClipListButton } from 'client/components/Sharing/ClipList';
import { ShareVideoButton } from 'client/components/Sharing/ShareVideoButton';
import { ProcedureFavoriteIcon } from 'client/components/VideoExport/ProcedureFavoriteIcon';
import { useListAllDevices } from 'client/hooks/device/useListAllDevices';
import { useListDoctors } from 'client/hooks/doctors/useListDoctors';
import { useGetExportList } from 'client/hooks/videoExport/useGetExportList';
import { useGetProcedureListForExport } from 'client/hooks/videoExport/useGetProcedureListForExport';
import clsx from 'clsx';
import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import * as PartnerServices from 'common/services/partnerServices';
import { IPartner } from 'common/types';
import CommonUtils from 'common/utils/CommonUtils';
import MUIDataTable, { SelectableRows, MUIDataTableColumn, Responsive } from 'mui-datatables';
import { ErrorBoundary } from 'react-error-boundary';
import { useQuery, useQueryErrorResetBoundary } from 'react-query';
import { VideoExportExpandedPanel } from './VideoExportExpandedPanel';

const formatMilliseconds = CommonUtils.formatMilliseconds;
const styles = {
    favoriteWidth: {
        width: '5px',
    },
    padding: {
        padding: '30px',
    },
    fontWeight: {
        fontWeight: 900,
    },
};

interface Props {
    patientId: string | undefined;
}

const VideoExportPage: React.FC<Props> = ({ patientId }) => {
    const [filter, setFilter] = useState('');
    const { data: clips } = useGetExportList();
    const { data: procedureListData, isLoading: procedureListIsLoading } = useGetProcedureListForExport(
        filter,
        undefined,
        undefined,
        undefined,
        patientId
    );
    const { reset } = useQueryErrorResetBoundary();
    const [selectedFavorite, setSelectedFavorite] = useState('');
    const { devices } = useListAllDevices();
    const { doctors } = useListDoctors({});
    const [companies, setCompanies] = useState<string[]>([]);
    const [brands, setBrands] = useState<string[]>([]);

    useQuery(['partners'], PartnerServices.getAllPartnersService, {
        enabled: true,
        onSuccess: success => {
            const allOptions: IPartner[] = success.data || [];
            setCompanies(allOptions.filter(obj => obj.category === 'COMPANY').map(r => r.value));
            setBrands(allOptions.filter(obj => obj.category === 'BRAND').map(r => r.value));
            setCompanies(prev => ['N/A', ...prev]);
            setBrands(prev => ['N/A', ...prev]);
        },
    });

    const handleFilter = (value: string) => {
        setFilter(value);
        setSelectedFavorite(value);
    };

    const PROCEDURE_LIST_COLUMN_OPTIONS: MUIDataTableColumn[] = [
        { name: 'favoriteId', options: { display: 'excluded', filter: false } },
        { name: 'favoriteType', options: { display: 'excluded', filter: false } },
        {
            name: 'isFavorite',
            label: 'Favorite',
            options: {
                setCellHeaderProps(columnMeta) {
                    return { className: clsx(columnMeta, styles.favoriteWidth) };
                },
                setCellProps: () => ({
                    style: {
                        textAlign: 'center',
                        paddingLeft: '1%',
                    },
                }),
                filter: false,
                customHeadLabelRender: columnMeta => {
                    return <span style={{ display: 'flex', justifyContent: 'center', fontWeight: 500, width: '30px' }}>{columnMeta.label}</span>;
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    //const favoritesId = tableMeta.rowData[0];
                    const procedureId = tableMeta.rowData[3];
                    // const jobId = undefined;
                    // const favoriteType = tableMeta.rowData[1];
                    return <ProcedureFavoriteIcon procedureId={procedureId} />;
                },
            },
        },
        {
            name: 'id',
            label: 'Procedure',
            options: {
                setCellProps: () => ({
                    style: {
                        paddingLeft: '4%',
                    },
                }),
                sortDirection: 'desc',
            },
        },
        {
            name: 'title',
            label: 'Title',
            options: {
                setCellProps: () => ({
                    style: {
                        textAlign: 'center',
                        paddingLeft: '1%',
                    },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value || 'N/A';
                },
            },
        },
        {
            name: 'doctor',
            label: 'Doctor',
            options: {
                setCellProps: () => ({
                    style: {
                        paddingLeft: '2%',
                    },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value?.name || 'N/A';
                },
            },
        },
        {
            name: 'startTime',
            label: 'Start',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const timeZone = tableMeta.rowData[15];
                    return new Date(value).toLocaleString('en-US', { timeZone: timeZone || 'UTC' });
                },
            },
        },
        {
            name: 'timeSpent',
            label: 'Length',
            options: {
                filter: true,
                filterOptions: {
                    renderValue: (value: any) => {
                        if (isNaN(value)) return value;
                        return formatMilliseconds(value);
                    },
                },
                display: true,
                setCellProps: () => ({
                    style: {
                        textAlign: 'center',
                    },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (!value) return 'N/A';
                    return <>{formatMilliseconds(value)}</>;
                },
            },
        },
        {
            name: 'brandName',
            label: 'Brand',
            options: {
                setCellProps: () => ({
                    style: {
                        paddingLeft: '3%',
                    },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value || 'N/A';
                },
            },
        },
        {
            name: 'deviceName',
            label: 'Device',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value || 'N/A';
                },
            },
        },
        {
            name: 'patientInitials',
            label: 'Patient Initials',
            options: {
                setCellProps: () => ({
                    style: {
                        textAlign: 'center',
                        paddingLeft: '2%',
                    },
                }),
            },
        },
        {
            name: 'device',
            label: 'Room Name',
            options: {
                setCellHeaderProps(columnMeta) {
                    return { className: clsx(columnMeta, styles.fontWeight) };
                },
                setCellProps: () => ({
                    style: {
                        textAlign: 'center',
                        paddingLeft: '1%',
                    },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value?.roomName;
                },
            },
        },
        {
            name: 'isShared',
            label: 'Shared with Me',
            options: {
                display: false,
            },
        },
        {
            name: 'share',
            label: 'Share',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const isShared = tableMeta.rowData[12];
                    const procedureId = tableMeta.rowData[3];
                    return (
                        !isShared && (
                            <div style={{ cursor: 'pointer', paddingLeft: '25%' }}>
                                <ShareVideoButton procedureId={procedureId} />
                            </div>
                        )
                    );
                },
            },
        },
        {
            name: 'clips',
            label: 'Clips',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (_value, tableMeta, _updateValue) => {
                    const procedureId = tableMeta.rowData[3];
                    const clipCount = clips?.filter(clip => clip.procedureId === procedureId).length;
                    return (
                        <div style={{ cursor: 'pointer', paddingLeft: '25%' }}>
                            <ClipListButton procedureId={procedureId} count={clipCount} />
                        </div>
                    );
                },
            },
        },
        {
            name: 'companyName',
            label: 'Company Name',
            options: {
                display: false,
            },
        },
        {
            name: 'timeZone',
            options: {
                display: false,
            },
        },
        {
            name: 'exportListTitles',
            options: {
                display: false,
            },
        },
    ];

    const procedureListDataTableOptions = {
        selectableRows: 'none' as SelectableRows,
        expandableRows: true,
        expandableRowsHeader: false,
        download: false,
        customSearch: (searchQuery: string, currentRow: any[], columns: any[]) => {
            if (currentRow[currentRow.length - 1].some((item: string) => item?.toLowerCase().includes(searchQuery.toLowerCase()))) return true;
            if (
                currentRow.some((item: any) => {
                    if (typeof item === 'string') {
                        return item.toLowerCase().includes(searchQuery.toLowerCase());
                    } else if (typeof item === 'object') {
                        return item?.name?.toLowerCase().includes(searchQuery.toLowerCase());
                    } else if (typeof item === 'number') {
                        return item?.toString().includes(searchQuery);
                    } else {
                        return false;
                    }
                })
            ) {
                return true;
            }
            return false;
        },
        print: false,
        viewColumns: false,
        rowsPerPageOptions: [5, 10, 20, 50, 100],
        rowsPerPage: 10,
        empty: true,
        sortThirdClickReset: true,
        responsive: 'simple' as Responsive,
        renderExpandableRow: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
            const expandingPanelData = {
                id: procedureListData[rowMeta.dataIndex].id,
                doctor: {
                    name: procedureListData[rowMeta.dataIndex]?.doctor?.name ?? 'N/A',
                },
                startTime: procedureListData[rowMeta.dataIndex].startTime,
                patientInitials: procedureListData[rowMeta.dataIndex].patientInitials,
                brandName: procedureListData[rowMeta.dataIndex].brandName,
                companyName: procedureListData[rowMeta.dataIndex].companyName,
                deviceName: procedureListData[rowMeta.dataIndex].deviceName,
                title: procedureListData[rowMeta.dataIndex].title,
                timeZone: procedureListData[rowMeta.dataIndex].timeZone,
                length: procedureListData[rowMeta.dataIndex].timeSpent,
                device: {
                    roomName: procedureListData[rowMeta.dataIndex].device.roomName,
                },
                procedureData: procedureListData,
                timeSpent: procedureListData[rowMeta.dataIndex].timeSpent,
                surgeryStart: procedureListData[rowMeta.dataIndex].surgeryStart,
                surgeryEnd: procedureListData[rowMeta.dataIndex].surgeryEnd,
                deviceId: procedureListData[rowMeta.dataIndex].deviceId,
                doctorId: procedureListData[rowMeta.dataIndex].doctorId,
            };
            const colSpan = rowData.length + 1;
            const lstDoctors = doctors.map((d: any) => ({ id: d.id.toString(), name: d.name }));
            lstDoctors.push({ id: '0', name: 'Unknown' });

            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>
                        <ErrorBoundary
                            onReset={reset}
                            fallbackRender={({ resetErrorBoundary }) => (
                                <div>
                                    <p>Oops! Something went wrong...</p>
                                    <Button onClick={() => resetErrorBoundary()}>Try again</Button>
                                </div>
                            )}
                        >
                            <Suspense fallback={<Skeleton />}>
                                <VideoExportExpandedPanel
                                    data={expandingPanelData}
                                    doctors={lstDoctors}
                                    devices={devices.map((d: any) => ({ id: d.id.toString(), name: d.roomName }))}
                                    companies={companies}
                                    brands={brands}
                                />
                            </Suspense>
                        </ErrorBoundary>
                    </TableCell>
                </TableRow>
            );
        },
    };

    const isLoading = procedureListIsLoading;
    const returnRender = isLoading ? (
        <ProcessingOverlay enabled caption="Loading..." />
    ) : (
        <Grid>
            <MUIDataTable
                title={
                    <Typography variant="h6">
                        <div style={{ marginBottom: '3%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ fontWeight: 500, alignItems: 'flex-end', display: 'flex', flexDirection: 'row' }}>
                                Procedures
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'right',
                                        width: '100%',
                                    }}
                                >
                                    <div style={{ marginTop: '2%', marginLeft: '10%', display: 'flex', flexDirection: 'column' }}>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={() => handleFilter('')}
                                            style={{
                                                justifyContent: 'flex-start',
                                                fontSize: '18px',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginLeft: '12%',
                                                cursor: 'pointer',
                                                background: selectedFavorite === '' ? '#0033661A' : 'none',
                                            }}
                                            endIcon={<FavoriteBorder style={{ marginBottom: '1%', marginLeft: '100px' }} />}
                                        >
                                            All Procedures:
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={() => handleFilter('procedure')}
                                            style={{
                                                justifyContent: 'flex-start',
                                                fontSize: '18px',
                                                marginTop: '4%',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginLeft: '12%',
                                                cursor: 'pointer',
                                                background: selectedFavorite === 'procedure' ? '#0033661A' : 'none',
                                            }}
                                            endIcon={<FavoriteSharp style={{ marginTop: '1%', marginLeft: '53px', color: '#A2D45E' }} />}
                                        >
                                            Favorite Procedures:
                                        </Button>
                                    </div>
                                    <div style={{ marginTop: '2%', marginLeft: '5%', display: 'flex', flexDirection: 'column' }}>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={() => handleFilter('job')}
                                            style={{
                                                justifyContent: 'flex-start',
                                                fontSize: '18px',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginLeft: '20%',
                                                cursor: 'pointer',
                                                background: selectedFavorite === 'job' ? '#0033661A' : 'none',
                                            }}
                                            endIcon={<StarHalf style={{ marginLeft: '118px', color: '#A2D45E' }} />}
                                        >
                                            Favorite Exports:
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={() => handleFilter('both')}
                                            style={{
                                                justifyContent: 'flex-start',
                                                fontSize: '18px',
                                                marginTop: '4%',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginLeft: '20%',
                                                cursor: 'pointer',
                                                background: selectedFavorite === 'both' ? '#0033661A' : 'none',
                                            }}
                                            endIcon={<StarRate style={{ marginBottom: '2%', color: '#A2D45E' }} />}
                                        >
                                            Favorite Procedures & Exports:
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                    </Typography>
                }
                data={procedureListData}
                columns={PROCEDURE_LIST_COLUMN_OPTIONS}
                options={procedureListDataTableOptions}
            />
        </Grid>
    );
    return returnRender;
};
const VideoExport: React.FC<Props> = ({ patientId }): JSX.Element => {
    return (
        <>
            <VideoExportPage patientId={patientId} />
        </>
    );
};
export default VideoExport;
